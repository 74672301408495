import React, { useState } from "react";
import { Label, FormGroup, Badge } from "reactstrap";
import { Image, Button, Form as FormSec } from "semantic-ui-react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  showSuccessToast,
  showErrorToast,
  showInfoToast,
} from "../components/Notify";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { saveAs } from "file-saver";
import { b64toBlob } from "../helpers/utils";

export default function QrView({ history }) {
  const [handleInputChange, setHandleInputChange] = useState("520-");
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [activateAndEdit, setActivateAndEdit] = useState({
    active: false,
    edit: false,
    form: false,
    download: false,
  });

  const formSchema = Yup.object().shape({
    folio: Yup.string()
      .min(15, "Deben ser 15 dígitos e iniciar con 520")
      .max(15, "Deben ser 15 dígitos e iniciar con 520")
      .required("Escriba el folio"),
    password: Yup.string()
      .min(4, "Deben ser al menos 4 dígitos")
      .max(12, "Deben ser máximo 12 dígitos")
      .required("Escriba una contraseña"),
  });

  async function downloadQr() {
    setLoadingInfo(true);

    const folio = handleInputChange;

    await Axios.get(`${ROOT_URL}/folio/${folio}`)
      .then((response) => {
        const contentType = "image/png";
        const b64Data = response.data;
        const blob = b64toBlob(b64Data, contentType);
        saveAs(blob, `${folio}.png`);
        showSuccessToast({
          title: "Mensaje",
          message: "Se ha descargado el QR",
        });
        setLoadingInfo(false);
        setHandleInputChange("520-")
      })
      .catch((error) => {
        showErrorToast({
          title: "Mensaje",
          message:
            error.response.data.message +
            ": Asegure de que sea similar a la siguiente estructura: 520-0123-XXXXXX",
        });
        setLoadingInfo(false);
      });
  }

  async function onSubmit(values) {
    const destination = activateAndEdit.active
      ? "activate-folio"
      : "edit-folio";

    try {
      await Axios.patch(
        `${ROOT_URL}/qr/${destination}/${values.folio}?password=${values.password}`
      ).then((response) => {
        if (response.status === 200) {
          showSuccessToast({
            title: "Mensaje",
            message: response.data.message,
          });
          history.push(`/edit/${response.data.qrId}`);
        } else {
          showInfoToast({
            title: "Mensaje",
            message: response.data.message,
          });
        }
      });
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
    }
  }

  return (
    <div className="Auth-form-container">
      <div className="Auth-form">
        <div className="Auth-form-content">
          <Image
            src="https://qrpdfs.s3.us-east-2.amazonaws.com/logo-stiqers-colorW2.png"
            fluid
          />
          <br />

          {/*Inicia la visualización de las 3 opciones*/}
          {!activateAndEdit.form && !activateAndEdit.download && (
            <>
              <div className="padd-bot-2">
                <Button
                  fluid
                  color="purple"
                  onClick={() =>
                    setActivateAndEdit({
                      edit: false,
                      active: true,
                      form: true,
                      download: false,
                    })
                  }
                >
                  Activar mi QR
                </Button>
              </div>

              <div className="padd-bot-2">
                <Button
                  fluid
                  color="purple"
                  onClick={() =>
                    setActivateAndEdit({
                      active: false,
                      edit: true,
                      form: true,
                      download: false,
                    })
                  }
                >
                  Editar mi QR
                </Button>
              </div>

              <div className="padd-bot-2">
                <Button
                  fluid
                  color="purple"
                  onClick={() =>
                    setActivateAndEdit({
                      active: false,
                      edit: false,
                      form: false,
                      download: true,
                    })
                  }
                >
                  Descargar mi QR
                </Button>
              </div>
            </>
          )}
          {/*Termina la visualización de las 3 opciones*/}

          {/*Inicia la activación y edición de folio */}
          {activateAndEdit.form && !activateAndEdit.download && (
            <Formik
              enableReinitialize={true}
              initialValues={{
                folio: "",
                password: "",
              }}
              validationSchema={formSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <FormGroup>
                    <Label className="input-title">* Ingrese el folio</Label>
                    <Field
                      type="text"
                      name="folio"
                      className="form-control"
                      required
                    />
                    {errors.folio && touched.folio ? (
                      <div>
                        <Badge className="badge-color" color="danger">
                          {errors.folio}
                        </Badge>
                      </div>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label className="input-title">
                      {activateAndEdit.active ? "* Ingrese una contraseña" : "* Contraseña"}
                    </Label>
                    <Field
                      type="text"
                      name="password"
                      className="form-control"
                      required
                    />
                    {errors.password && touched.password ? (
                      <div>
                        <Badge className="badge-color" color="danger">
                          {errors.password}
                        </Badge>
                      </div>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <div className="padd-bot-2">
                      <Button fluid color="purple" type="submit">
                        {activateAndEdit.active ? "Activar" : "Editar"}
                      </Button>
                    </div>
                    <div className="padd-bot-2">
                      <Button
                        fluid
                        color="black"
                        outline
                        onClick={() =>
                          setActivateAndEdit({
                            ...activateAndEdit,
                            form: false,
                          })
                        }
                      >
                        Regresar
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          )}
          {/*Termina la activación y edición de folio */}

          {!activateAndEdit.form && activateAndEdit.download && (
            <FormGroup>
              <FormSec.Group widths="equal">
                <FormSec.Input
                  fluid
                  label="Fólio"
                  required
                  onChange={(e) => setHandleInputChange(e.target.value)}
                  value={handleInputChange}
                  className="padd-bot-2"
                  icon="search"
                  loading={loadingInfo}
                  maxLength="15"
                />
              </FormSec.Group>
              <div className="padd-bot-2">
                <Button
                  fluid
                  color="purple"
                  type="button"
                  loading={loadingInfo}
                  onClick={downloadQr}
                >
                  Descargar
                </Button>
              </div>
              <div className="padd-bot-2">
                <Button
                  fluid
                  color="black"
                  outline
                  onClick={() =>
                    setActivateAndEdit({
                      ...activateAndEdit,
                      form: false,
                      download: false,
                    })
                  }
                >
                  Regresar
                </Button>
              </div>
            </FormGroup>
          )}
        </div>
      </div>
    </div>
  );
}
