import React, { useReducer, useState } from "react";
import {
  Button,
  Modal as MdlSmt,
  Form as FormSec,
  Form,
  Message,
} from "semantic-ui-react";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { showSuccessToast, showErrorToast } from "../components/Notify";

function ModalReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

export default function CreateCompany({ loading, setLoading }) {
  const [state, dispatch] = useReducer(ModalReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;
  const [infoCompany, setInfoCompany] = useState("");
  const initialState = {
    value: false,
    msg: "",
    existingRecord: false,
  };
  const [info, setInfo] = useState(initialState);

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    try {
      await Axios.post(`${ROOT_URL}/companies/`, { Name: infoCompany }).then(
        (response) => {
          showSuccessToast({
            title: "Mensaje",
            message: response.data.message,
          });
          setLoading(false);
          dispatch({ type: "CLOSE_MODAL" });
          setInfo(initialState);
        }
      );
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
      dispatch({ type: "CLOSE_MODAL" });
      setInfo(initialState);
    }
  }

  return (
    <div>
      <Button
        color="purple"
        size="small"
        onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
        floated="right"
      >
        Crear Empresa
      </Button>
      <MdlSmt
        centered={true}
        size="small"
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        as={Form}
        onSubmit={(e) => handleSubmit(e)}
        loading={loading}
      >
        <MdlSmt.Header>
          Crear
          {info.value && (
            <Message negative content={info.msg} compact size="tiny" />
          )}
        </MdlSmt.Header>
        <MdlSmt.Content>
          <FormSec.Group widths="equal">
            <FormSec.Input
              fluid
              label="Nombre"
              placeholder="Name"
              required
              onChange={(e) => setInfoCompany(e.target.value)}
              name="Name"
              value={infoCompany}
            />
          </FormSec.Group>
        </MdlSmt.Content>
        <MdlSmt.Actions>
          <Button
            color="green"
            type="submit"
            loading={loading}
            disabled={!infoCompany}
          >
            Crear
          </Button>
          <Button
            color="red"
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
            type="button"
          >
            Cancelar
          </Button>
        </MdlSmt.Actions>
      </MdlSmt>
    </div>
  );
}
