import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Axios from "axios";
import {
  setToken,
  deleteToken,
  getToken,
  initAxiosInterceptors,
} from "./helpers/auth-helpers";
import { ROOT_URL } from "./constants/defaultValues";
import Login from "./views/Login";
import Loading from "./components/Loading";
import Panel from "./views/Panel";
import QrView from "./views/QrView";
import Qr from "./views/Qr";
import { showSuccessToast } from "./components/Notify";
import "./App.css";
import CodeList from "./views/CodeList";
import Invoice from "./views/Invoice";
import UserList from "./views/UserList";
import ListCompanies from "./views/ListCompanies";

/*Intercepta todas las llamadas y coloca un encabezado 
con el token*/
initAxiosInterceptors();

export default function App() {
  const [user, setUser] = useState(null);
  const [properties, setProps] = useState({
    role: "Supervisor",
    suscription: true,
  });
  const [loadingUser, setLoadingUser] = useState(true);

  //Verifica si existe un usuario autenticado
  useEffect(() => {
    async function loadUser() {
      if (!getToken()) {
        setLoadingUser(false);
        return;
      }

      try {
        const { data } = await Axios.get(`${ROOT_URL}/auth/whoami`);
        setUser(data.user);
        setProps(data.props);
        setLoadingUser(false);
      } catch (error) {
        console.log(error);
      }
    }
    loadUser();
  }, []);

  //Recibe email y password de la vista login y manda un request
  //al recurso
  async function login(Email, Password) {
    const { data } = await Axios.post(`${ROOT_URL}/auth/login`, {
      Email,
      Password,
    });
    setUser(data.user);
    setProps(data.props);
    setToken(data.token);
    showSuccessToast({
      title: "Éxito",
      message: data.message,
    });
  }

  function logout() {
    setUser(null);
    setProps({
      role: "Supervisor",
      suscription: true,
    });
    deleteToken();
  }

  /*Si se está cargando un usuario, se muestra el 
  componente loading*/
  if (loadingUser) {
    return <Loading />;
  }

  return (
    <Router>
      {/*Se mandan props y métodos a los componetes/vistas */}
      <ToastContainer />
      {user ? (
        <LoginRoutes user={user} logout={logout} properties={properties} />
      ) : (
        <LogoutRoutes login={login} />
      )}
    </Router>
  );
}

/*Maneja todas las vistas a las que pueden 
tener acceso los usuarios registrados*/
function LoginRoutes({ user, logout, properties }) {
  return (
    <Switch>
      <Route
        path="/list-companies"
        render={(props) => (
          <ListCompanies {...props} properties={properties} user={user} />
        )}
      />
      <Route
        path="/user-list"
        render={(props) => (
          <UserList {...props} properties={properties} user={user} />
        )}
      />
      <Route
        path="/code-list/invoice/:id"
        render={(props) => (
          <Invoice {...props} properties={properties} user={user} edit={false}/>
        )}
      />
      <Route
        path="/code-list"
        render={(props) => (
          <CodeList {...props} properties={properties} user={user} />
        )}
      />
      <Route
        path="/"
        render={(props) => (
          <Panel {...props} properties={properties} user={user} logout={logout}/>
        )}
        default
      />
    </Switch>
  );
}

/*Maneja todas las vistas a las que pueden 
tener acceso los usuarios que no están registrados */
function LogoutRoutes({ login }) {
  return (
    <Switch>
      <Route
        path="/edit/:id"
        render={(props) => <Invoice {...props} edit={true}/>}
        exact
      />
      <Route
        path="/auth"
        render={(props) => <Login {...props} login={login} />}
      />
      <Route path="/:id" render={(props) => <Qr {...props} />} />
      <Route default path="/" render={(props) => <QrView {...props} />}  />
    </Switch>
  );
}
