import React from "react";
import { Button, Image } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import CreateBatchModal from "../containers/CreateBatchModal";
import FindModal from "../containers/FindModal";

export default function Panel({ logout }) {
  return (
    <div className="Auth-form-container">
      <div className="Auth-form">
        <div className="Auth-form-content">
          <div className="padd-bot-10">
            <Image
              src="https://qrpdfs.s3.us-east-2.amazonaws.com/logo-stiqers-colorW2.png"
              fluid
            />
          </div>

          <div className="padd-bot-2">
            <CreateBatchModal />
          </div>

          <div className="padd-bot-2">
            <NavLink to={`/code-list`}>
              <Button
                content="Visualizar QRs"
                icon="eye"
                labelPosition="right"
                color="teal"
                fluid
              />
            </NavLink>
          </div>

          <div className="padd-bot-2">
            <FindModal />
          </div>

          <div className="padd-bot-2">
            <NavLink to={`/user-list`}>
              <Button
                fluid
                color="teal"
                content="Panel de usuarios"
                icon="users"
                labelPosition="right"
              />
            </NavLink>
          </div>

          <div className="padd-bot-2">
            <NavLink to={`/list-companies`}>
              <Button
                fluid
                color="teal"
                content="Gestión de empresas"
                icon="building"
                labelPosition="right"
              />
            </NavLink>
          </div>

          <div className="padd-bot-2">
            <Button
              fluid
              color="purple"
              content="Salir"
              icon="arrow alternate circle left"
              labelPosition="right"
              onClick={logout}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
