import React from "react";
import { ToastContainer as Toastr, toast } from "react-toastify";
import { toastTypes } from "../constants/defaultValues";
import classNames from "classnames";

const { ERROR, SUCCESS, WARNING, INFO } = toastTypes;

export const ToastContainer = () => {
  return (
    <Toastr
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
      theme="dark"
    />
  );
};

const ToastComponent = (props) => {
  const { message, title, type } = props;

  const toastMessage = message;
  const toastTitle = title;

  return (
    <div className="toast-component">
      <div className="toast-icon">
        <i
          className={classNames("fas", {
            "fa-info-circle": type === INFO,
            "fa-check-circle": type === SUCCESS,
            "fa-exclamation-circle": type === WARNING,
            "fa-times-circle": type === ERROR,
          })}
        />
      </div>
      <div className="toast-content">
        {toastTitle && <div className="title">{toastTitle}</div>}
        <div className="message">{toastMessage}</div>
      </div>
    </div>
  );
};

export const showToast = (message, options = {}, type = ERROR) => {
  const toastMessage = message?.message || message;
  const toastTitle = message?.title || null;

  const renderToast = (
    <ToastComponent message={toastMessage} title={toastTitle} type={type} />
  );

  const toastOptions = { ...options };

  switch (type) {
    case SUCCESS:
      toast.success(renderToast, toastOptions);
      break;
    case WARNING:
      toast.warning(renderToast, toastOptions);
      break;
    case INFO:
      toast.info(renderToast, toastOptions);
      break;
    default:
      toast.error(renderToast, toastOptions);
  }
};

export const showErrorToast = (message, options = {}) =>
  showToast(message, options, ERROR);
export const showSuccessToast = (message, options = {}) =>
  showToast(message, options, SUCCESS);
export const showInfoToast = (message, options = {}) =>
  showToast(message, options, INFO);
export const showWarningToast = (message, options = {}) =>
  showToast(message, options, WARNING);
