import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Icon } from "semantic-ui-react";
import Loading from "../components/Loading";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { stringDate } from "../helpers/utils";
import { confirmAlert } from "react-confirm-alert";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { showSuccessToast, showErrorToast } from "../components/Notify";
import CreateCompany from "../containers/CreateCompany";
import BreadcrumbTop from "../components/BreadcrumbTop";

export default function ListCompanies() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const sections = [{ content: "Lista de empresas", destination: "/null" }];

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await Axios.get(`${ROOT_URL}/companies/`);
        setData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    loadData();
  }, [loading]);

  const erase = (id) => {
    confirmAlert({
      message: `¿Desea borrar ${id}? Esta acción no se puede deshacer`,
      buttons: [
        {
          label: "Si",
          onClick: () => deleteItem(id),
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  async function deleteItem(id) {
    setLoading(true);

    try {
      await Axios.delete(`${ROOT_URL}/companies/${id}`).then((response) => {
        showSuccessToast({
          title: "Mensaje",
          message: response.data.message,
        });
        setLoading(false);
      });
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
    }
  }

  const columns = [
    {
      name: "Nombre",
      selector: "Name",
    },
    {
      name: "Creado",
      selector: "CreatedAt",
      sortable: true,
      cell: (d) => stringDate(d.CreatedAt),
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <Icon
          key={d.Id}
          onClick={() => erase(d.Id)}
          name="trash"
          size="large"
          link
          color="red"
        />,
      ],
    },
  ];

  const tableData = {
    columns,
    data,
  };

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <BreadcrumbTop sections={sections} />
      <Container className="padd-1" fluid={true}>
        <Row>
          <Col xs="12">
            <Card className="trans">
              <CardBody>
                <Row>
                  <div className="main">
                    <CreateCompany loading={loading} setLoading={setLoading} />
                    <DataTableExtensions {...tableData}>
                      <DataTable
                        columns={columns}
                        data={data}
                        noHeader
                        defaultSortField="Id"
                        sortIcon={<Icon name="sort up" size="large" link />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      />
                    </DataTableExtensions>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
