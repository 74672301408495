import React from "react";
import { Button, InputGroup, Spinner } from "reactstrap";
import { ROOT_URL, style } from "../constants/defaultValues";
import Axios from "axios";
import { Image } from "semantic-ui-react";

export default function FileInput({
  fileProps,
  setFileProps,
  uploadingFile,
  setUploadingFile,
  accept,
  qrId,
  disabled
}) {
  async function handleFileSelected(e) {
    setUploadingFile(true);

    try {
      const file = e.target.files[0];

      let formData = new FormData();
      formData.append("image", file);

      const config = {
        headers: {
          "Content-Type": file.type,
        },
      };

      await Axios.post(`${ROOT_URL}/compress`, formData, config)
        .then((response) => {
          setFileProps(response.data.url);
          setUploadingFile(false);
        })
        .catch((error) => {
          setUploadingFile(false);
        });
    } catch (error) {
      setUploadingFile(false);
    }
  }

  async function handleDeleteFile() {
    setUploadingFile(true);
    // console.log(fileProps.substring(48));
    try {
      await Axios.delete(`${ROOT_URL}/image/${fileProps.substring(48)}`).then(
        () => {
          Axios.put(`${ROOT_URL}/qr/${qrId}`, { Image: "" }).then(() => {
            setFileProps(null);
            setUploadingFile(false);
          });
        }
      );
    } catch (error) {
      setUploadingFile(false);
    }
  }

  return (
    <InputGroup>
      {uploadingFile ? (
        <div style={style[0]}>
          <Spinner size="sm" color="primary" style={style[0]} />
        </div>
      ) : fileProps ? (
        <>
          <Image
            src={fileProps && fileProps.url ? fileProps.url : fileProps}
            alt="thumbnail"
            size="large"
            rounded
            centered
          />

          <div style={style[3]}>
            <Button
              color="danger"
              onClick={handleDeleteFile}
              size="xs"
            >
              Borrar
            </Button>
          </div>
        </>
      ) : (
        <div className="Upload">
          <div className="Upload__image-section">
            <label className="Upload__image-label">
              <span> Selecciona una imagen</span>
              <input
                type="file"
                onChange={handleFileSelected}
                disabled={disabled}
                accept={accept}
                className="hidden"
              />
            </label>
          </div>
        </div>
      )}
    </InputGroup>
  );
}
