export const ROOT_URL =
   "https://r31zzk1q1l.execute-api.us-east-2.amazonaws.com/production/api/v1";
//export const ROOT_URL = "http://127.0.0.1:5000/api/v1";

export const toastTypes = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};

export const style = [
  {
    display: "flex",
    justifyContent: "center",
  },
  {
    maxWidth: "55px",
  },
  {
    maxHeight: "75%",
  },
  {
    paddingTop: "23px",
    paddingLeft: "10px",
  },
  {
    maxWidth: "250px",
  },
];
