import React from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { Header } from "semantic-ui-react";

export default function NotFound() {
  return (
    <Container className="padd-2" fluid={true}>
      <Row>
        <Col sm="12">
          <Card className="trans">
            <CardBody>
              <Header as="h1">El elemento no existe</Header>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
