export const stringDate = (d) => {
  let _d = new Date(d * 1000);
  return (
    _d.getDate() +
    "/" +
    (_d.getMonth() + 1) +
    "/" +
    _d.getFullYear() +
    " " +
    _d.getHours() +
    ":" +
    _d.getMinutes() +
    ":" +
    _d.getSeconds()
  );
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
