import React, { useReducer, useState } from "react";
import {
  Button,
  Modal as MdlSmt,
  Form as FormSec,
  Form,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";

function ModalReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

export default function FindModal() {
  const [state, dispatch] = useReducer(ModalReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;
  const [infoBatch, setInfoBatch] = useState("");

  function handleInputChange(e) {
    setInfoBatch(e.target.value);
  }

  return (
    <div>
      <Button
        content="Buscador de QR"
        icon="search"
        labelPosition="right"
        color="teal"
        fluid
        onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
      />

      <MdlSmt
        centered={true}
        size="small"
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        as={Form}
      >
        <MdlSmt.Header>Buscar</MdlSmt.Header>
        <MdlSmt.Content>
          <FormSec.Group widths="equal">
            <FormSec.Input
              fluid
              label="Id"
              placeholder="Id"
              required
              onChange={handleInputChange}
              name="Id"
            />
          </FormSec.Group>
        </MdlSmt.Content>
        <MdlSmt.Actions>
          <NavLink to={`/code-list/invoice/${infoBatch}`}>
            <Button
              color="green"
              disabled={infoBatch === "" || infoBatch.length < 23}
            >
              Buscar
            </Button>
          </NavLink>
          <Button
            color="red"
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
            type="button"
          >
            Cancelar
          </Button>
        </MdlSmt.Actions>
      </MdlSmt>
    </div>
  );
}
