import React, { useReducer, useState, forwardRef, useEffect } from "react";
import {
  Button,
  Modal as MdlSmt,
  Form as FormSec,
  Icon,
  Form,
  Message,
  Label,
} from "semantic-ui-react";
import Axios from "axios";
import DatePicker from "react-datepicker";
import { ROOT_URL } from "../constants/defaultValues";
import { showSuccessToast, showErrorToast } from "../components/Notify";

function ModalReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

export default function CreateBatchModal() {
  const [state, dispatch] = useReducer(ModalReducer, {
    open: false,
    dimmer: undefined,
  });
  const [loading, setLoading] = useState(false);
  const today = new Date();
  const lastDayOfMonth = new Date(
    today.getFullYear() + 1,
    today.getMonth() + 1,
    0
  );
  const [infoBatch, setInfoBatch] = useState({
    DueDate: lastDayOfMonth,
    Quantity: 1,
    Property: "General",
  });
  const initialState = {
    value: false,
    msg: "",
    existingRecord: false,
  };
  const [info, setInfo] = useState(initialState);
  const { open, dimmer } = state;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await Axios.get(`${ROOT_URL}/companies/`);
        const _opt = [];
        data.map((opt) =>
          _opt.push({ key: opt.Id, text: opt.Name, value: opt.Name })
        );

        setOptions(_opt);
      } catch (error) {
        console.log(error);
      }
    }
    loadData();
  }, []);

  const ContractDate = forwardRef(({ value, onClick }, ref) => (
    <Button as="div" labelPosition="right" onClick={onClick} ref={ref}>
      <Button icon type="button">
        <Icon name="calendar alternate" />
      </Button>
      <Label as="a" basic>
        {value}
      </Label>
    </Button>
  ));

  async function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      ...infoBatch,
      DueDate: parseInt(
        (new Date(infoBatch.DueDate).getTime() / 1000).toFixed(0)
      ),
    };

    setLoading(true);

    try {
      await Axios.post(`${ROOT_URL}/qr`, payload).then((response) => {
        showSuccessToast({
          title: "Mensaje",
          message: response.data.message,
        });
        setLoading(false);
        dispatch({ type: "CLOSE_MODAL" });
        setInfo(initialState);
      });
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
      dispatch({ type: "CLOSE_MODAL" });
      setInfo(initialState);
    }
  }

  function handleInputChange(e) {
    e.target
      ? setInfoBatch({
          ...infoBatch,
          [e.target.name]: e.target.value,
        })
      : setInfoBatch({
          ...infoBatch,
          Property: e,
        });
  }

  return (
    <div>
      <Button
        content="Generar códigos QR"
        icon="qrcode"
        labelPosition="right"
        color="teal"
        fluid
        onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
      />

      <MdlSmt
        centered={true}
        size="small"
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        as={Form}
        onSubmit={(e) => handleSubmit(e)}
        loading={loading}
      >
        <MdlSmt.Header>
          Crear
          {info.value && (
            <Message negative content={info.msg} compact size="tiny" />
          )}
        </MdlSmt.Header>
        <MdlSmt.Content>
          <FormSec.Group widths="equal">
            <FormSec.Select
              fluid
              label="Empresa"
              required
              name="Property"
              options={options}
              onChange={(e, { value }) => handleInputChange(value)}
              value={infoBatch.Property}
            />
            <FormSec.Input
              fluid
              label="Cantidad"
              required
              onChange={handleInputChange}
              name="Quantity"
              value={infoBatch.Quantity}
              min={1}
              max={1000}
              type="number"
            />
            <FormSec.Input fluid label="Fecha de vencimiento" required>
              <DatePicker
                selected={infoBatch.DueDate}
                onChange={(date) =>
                  setInfoBatch({
                    ...infoBatch,
                    DueDate: date,
                  })
                }
                customInput={<ContractDate />}
              />
            </FormSec.Input>
          </FormSec.Group>
        </MdlSmt.Content>
        <MdlSmt.Actions>
          <Button color="green" type="submit" loading={loading}>
            Crear
          </Button>
          <Button
            color="red"
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
            type="button"
          >
            Cancelar
          </Button>
        </MdlSmt.Actions>
      </MdlSmt>
    </div>
  );
}
