import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const invoiceSchema = Yup.object().shape({
  URL: Yup.string().url().notRequired(),
  Text: Yup.string().notRequired(),
  PhoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10)
    .max(13)
    .notRequired(),
  Map: Yup.string().url().notRequired(),
  YouTube: Yup.string().url().notRequired(),
  Password: Yup.string().notRequired().min(5),
});

export const initialInvoiceValues = {
  URL: "",
  Text: "",
  PhoneNumber: "",
  Map: "",
  YouTube: "",
  Password: null,
};
