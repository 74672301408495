import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { Col, Badge, FormGroup } from "reactstrap";
import { Button, Image, Header } from "semantic-ui-react";
import { Formik, Form, Field } from "formik";
import NotFound from "../components/NotFound";
import * as Yup from "yup";
import { showErrorToast } from "../components/Notify";
import QrInfo from "../containers/QrInfo";

export default function Qr({ match }) {
  const id = match.params.id;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(true);
  const [info, setInfo] = useState([]);
  const [enablePasswordInput, setEnablePasswordInput] = useState(false);
  const [className, setClassName] = useState("Auth-form");

  const enablePassworSchema = Yup.object().shape({
    pss: Yup.string().required().min(5),
  });

  useEffect(() => {
    async function loadData() {
      try {
        //se obtiene la informacion del qr escaneado. si no tiene proteccion pasa directamente
        const { data } = await Axios.get(`${ROOT_URL}/qr/${id}`);
        setInfo(data);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 400) {
          setEnablePasswordInput(true);
        } else {
          setNoData(false);
        }
        setLoading(false);
      }
    }
    loadData();
  }, []);

  const unlock = async (values) => {
    setLoading(true);

    try {
      const { data } = await Axios.get(
        `${ROOT_URL}/qr/${id}?action=view&password=${values.pss}`
      );
      setEnablePasswordInput(false);
      setInfo(data);
      if (data.Data.Image) {
        setClassName("Auth-form-qr");
      }
      setLoading(false);
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!loading && !noData) {
    return <NotFound />;
  }

  return (
    <div className="padd-bot-top-10">
      <div className="Auth-form-container">
        <div className={className}>
          <div className="Auth-form-content">
            {enablePasswordInput ? (
              <>
                <Formik
                  enableReinitialize={false}
                  initialValues={{ pss: null }}
                  validationSchema={enablePassworSchema}
                >
                  {({ errors, touched, values }) => {
                    return (
                      <Form>
                        <FormGroup row>
                          <Col md="12">
                            <FormGroup className="required">
                              <label>Ingrese la contraseña del QR</label>
                              <Field
                                type="password"
                                name="pss"
                                className="form-control"
                              />
                              {errors.pss && touched.pss ? (
                                <div>
                                  <Badge className="badge-color" color="danger">
                                    {errors.pss}
                                  </Badge>
                                </div>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col sm="12">
                            <FormGroup>
                              <Button
                                content="Enviar"
                                color="purple"
                                onClick={() => unlock(values)}
                              />
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            ) : info.Status === "Inactive" ? (
              <Col md="12" className="padd-bot-3">
                <Image
                  src="https://cdn-icons-png.flaticon.com/512/5372/5372351.png"
                  fluid
                />
                <Header as="h3" className="align-center">
                  Lo sentimos, este QR no está activo
                </Header>
              </Col>
            ) : (
              <QrInfo info={info} obj={"qr"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
