import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
  Icon,
  MessageHeader,
  Message,
  Button,
  MessageContent,
} from "semantic-ui-react";
import Loading from "../components/Loading";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { stringDate, b64toBlob } from "../helpers/utils";
import { confirmAlert } from "react-confirm-alert";
import { NavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
} from "reactstrap";
import {
  showSuccessToast,
  showErrorToast,
  showInfoToast,
} from "../components/Notify";
import { saveAs } from "file-saver";
import BreadcrumbTop from "../components/BreadcrumbTop";

export default function CodeList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const selected = selectedRows.length > 0 ? true : false;

  const sections = [{ content: "Lista de códigos", destination: "/null" }];

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await Axios.get(`${ROOT_URL}/qr`);
        setData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    loadData();
  }, [loading]);

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const erase = (id) => {
    confirmAlert({
      message: `¿Desea borrar ${id}? Esta acción no se puede deshacer`,
      buttons: [
        {
          label: "Si",
          onClick: () => deleteItem(id),
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const change = (id, folio, status) => {
    const query =
      status === "Inactive"
        ? `${ROOT_URL}/qr/activate/${id}?Folio=${folio}`
        : `${ROOT_URL}/qr/deactivate/${id}`;
    changeItem(query);
  };

  const downloadQr = async (id, folio) => {
    await Axios.get(`${ROOT_URL}/qr/${id}?action=image`)
      .then((response) => {
        const contentType = "image/png";
        const b64Data = response.data;
        const blob = b64toBlob(b64Data, contentType);
        saveAs(blob, `${folio}.png`);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  async function deleteItem(id) {
    setLoading(true);

    try {
      await Axios.delete(`${ROOT_URL}/qr/${id}`).then((response) => {
        showSuccessToast({
          title: "Mensaje",
          message: response.data.message,
        });
        setLoading(false);
      });
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
    }
  }

  async function changeItem(query) {
    setLoading(true);

    try {
      await Axios.patch(query).then((response) => {
        if (response.status === 200) {
          showSuccessToast({
            title: "Mensaje",
            message: response.data.message,
          });
          setLoading(false);
        } else {
          showInfoToast({
            title: "Mensaje",
            message: response.data.message,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
    }
  }

  async function exportPDFSelected() {
    const data = selectedRows.map((row) => {
      return {
        S3Key: row.S3Key,
        Folio: row.Folio,
      };
    });

    const payload = {
      KeysFolios: data,
    };

    setBtnLoading(true);

    try {
      const { data } = await Axios.post(`${ROOT_URL}/qr/pdf`, payload);
      const { url } = await data;

      saveAs(url);

      if (url) {
        showSuccessToast({
          title: "Mensaje",
          message: data.message,
        });
        setBtnLoading(false);
      }
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setBtnLoading(false);
    }
  }

  const columns = [
    {
      name: "Creado",
      selector: "CreatedAt",
      sortable: true,
      cell: (d) => stringDate(d.CreatedAt),
    },
    {
      name: "Estatus",
      selector: "Status",
      sortable: true,
    },
    {
      name: "Fecha de vencimiento",
      selector: "DueDate",
      sortable: true,
      cell: (d) => stringDate(d.DueDate),
    },
    {
      name: "Folio",
      selector: "Folio",
      sortable: true,
    },
    {
      name: "Batch ID",
      selector: "BatchId",
      sortable: true,
    },
    {
      name: "Propiedad",
      selector: "Property",
      sortable: true,
    },
    {
      name: "Contraseña",
      selector: "Password",
    },
    {
      name: "Escaneos",
      selector: "Views",
    },
    {
      name: "Acciones",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <Icon
          onClick={() => erase(d.Id)}
          name="trash"
          size="large"
          link
          color="red"
        />,
        <FormGroup switch>
          <Input
            type="switch"
            checked={d.Status === "Inactive" ? false : true}
            onClick={() => {
              change(d.Id, d.Folio, d.Status);
            }}
          />
        </FormGroup>,
        <a href={`code-list/invoice/${d.Id}`}>
          <Icon name="eye" size="large" link color="blue" />
        </a>,
        <Icon
          onClick={() => downloadQr(d.Id, d.Folio)}
          name="qrcode"
          size="large"
          link
          color="green"
        />,
      ],
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.Status !== "Inactive",
      style: {
        backgroundColor: "#57ef3259",
        color: "rgb(24 101 6)",
        "&:hover": {
          cursor: "not-allowed",
        },
      },
    },
  ];

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <BreadcrumbTop sections={sections} />
      <Container className="padd-1" fluid={true}>
        <Row>
          <Col xs="12">
            <Card className="trans">
              <CardBody>
                {selected && (
                  <Message color="blue">
                    <MessageHeader>Exportar a PDF</MessageHeader>
                    <MessageContent>
                      Se exportaran a PDF los registros seleccionados
                    </MessageContent>
                    <br />
                    <Button
                      primary
                      content="Exportar"
                      onClick={exportPDFSelected}
                      loading={btnLoading}
                    />
                  </Message>
                )}
                <Row>
                  <div className="main">
                    <DataTableExtensions {...tableData}>
                      <DataTable
                        columns={columns}
                        data={data}
                        defaultSortField="Id"
                        sortIcon={<Icon name="sort up" size="large" link />}
                        pagination
                        highlightOnHover
                        dense
                        conditionalRowStyles={conditionalRowStyles}
                        selectableRows
                        onSelectedRowsChange={handleChange}
                      />
                    </DataTableExtensions>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
