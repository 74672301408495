import React, { useEffect } from "react";
import { Col } from "reactstrap";
import {
  Button,
  ListItem,
  ListContent,
  List,
  Icon,
  Embed,
  Segment,
  Image,
} from "semantic-ui-react";
import parse from "html-react-parser";

export default function QrInfo({ info, obj }) {
  useEffect(() => {
    function loadingData() {
      const link = info.Data.URL;

      if (link) {
        if (obj === "qr") window.open(link, "_self", "noreferrer");
      }
    }
    loadingData();
  }, [info]);

  return (
    <Col md="12" className="padd-bot-3">
      <List divided verticalAlign="middle">
        {info.Data.URL ? (
          <>
            {obj === "invoice" && (
              <iframe
                src={info.Data.URL}
                sandbox="allow-scripts allow-same-origin"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                width="100%"
                height="665px"
                title="mypage"
              ></iframe>
            )}
            <ListItem>
              <ListContent floated="right">
                <a href={info.Data.URL} target="_blank" rel="noreferrer">
                  <Button icon labelPosition="right" color="teal">
                    Ir
                    <Icon name="globe" />
                  </Button>
                </a>
              </ListContent>
              <ListContent>
                <br />
                <strong>Enlace: </strong>
                {info.Data.URL}
              </ListContent>
            </ListItem>
          </>
        ) : (
          <>
            {info.Data.Image && (
              <Image src={info.Data.Image} fluid size="big" rounded centered />
            )}
            {info.Data.AudioURL && (
              <ListItem>
                <ListContent>
                  <br />
                  <strong>Audio: </strong>
                  <audio src={info.Data.AudioURL} controls="controls" />
                </ListContent>
              </ListItem>
            )}
            <br />
            {info.Data.PhoneNumber && (
              <>
                <ListItem>
                  <ListContent floated="right">
                    <a href={`tel:+52${info.Data.PhoneNumber}`}>
                      <Button icon labelPosition="right" color="teal">
                        Llamar
                        <Icon name="phone" />
                      </Button>
                    </a>
                  </ListContent>
                  <ListContent>
                    <br />
                    <strong>Telefono:</strong> {info.Data.PhoneNumber}
                  </ListContent>
                </ListItem>
              </>
            )}
            {info.Data.YouTube && (
              <>
                <ListItem>
                  <ListContent>
                    <br />
                    <Embed
                      placeholder="https://assets.nintendo.com/image/upload/c_fill,w_1200/q_auto:best/f_auto/dpr_2.0/ncom/es_LA/games/switch/y/youtube-switch/hero"
                      id={info.Data.YouTube.substring(32)}
                      source="youtube"
                    />
                  </ListContent>
                </ListItem>
              </>
            )}
            {!info.Data.Text ||
              info.Data.Text === "<p></p>" ||
              (info.Data.Text !== "" && (
                <>
                  <ListItem>
                    <ListContent>
                      <br />
                      <Segment>{parse(info.Data.Text)} </Segment>
                    </ListContent>
                  </ListItem>
                </>
              ))}
          </>
        )}
      </List>
    </Col>
  );
}
