import React, { useReducer, useState } from "react";
import {
  Button,
  Modal as MdlSmt,
  Form as FormSec,
  Form,
  Message,
} from "semantic-ui-react";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { showSuccessToast, showErrorToast } from "../components/Notify";

function ModalReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

export default function CreateUser({ loading, setLoading }) {
  const [state, dispatch] = useReducer(ModalReducer, {
    open: false,
    dimmer: undefined,
  });
  const [infoUser, setInfoUser] = useState({
    Email: "",
    Password: "",
    Username: "",
    UserType: "Supervisor",
  });
  const initialState = {
    value: false,
    msg: "",
    existingRecord: false,
  };
  const [info, setInfo] = useState(initialState);
  const { open, dimmer } = state;

  async function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      ...infoUser,
      Username: infoUser.Name,
      UserType: "Supervisor",
    };

    setLoading(true);

    try {
      await Axios.post(`${ROOT_URL}/auth/signup`, payload).then((response) => {
        showSuccessToast({
          title: "Mensaje",
          message: `Usuario ${infoUser.Name} creado.`,
        });
        setLoading(false);
        dispatch({ type: "CLOSE_MODAL" });
        setInfo(initialState);
      });
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
      dispatch({ type: "CLOSE_MODAL" });
      setInfo(initialState);
    }
  }

  function handleInputChange(e) {
    setInfoUser({
      ...infoUser,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div>
      <Button
        color="purple"
        size="small"
        onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
        floated="right"
      >
        Crear Usuario
      </Button>
      <MdlSmt
        centered={true}
        size="small"
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        as={Form}
        onSubmit={(e) => handleSubmit(e)}
        loading={loading}
      >
        <MdlSmt.Header>
          Crear
          {info.value && (
            <Message negative content={info.msg} compact size="tiny" />
          )}
        </MdlSmt.Header>
        <MdlSmt.Content>
          <FormSec.Group widths="equal">
            <FormSec.Input
              fluid
              label="Email"
              placeholder="Email"
              required
              onChange={handleInputChange}
              name="Email"
              value={infoUser.Email}
              type="email"
            />
            <FormSec.Input
              fluid
              label="Password"
              placeholder="Password"
              required
              onChange={handleInputChange}
              name="Password"
              value={infoUser.Password}
              type="password"
            />
            <FormSec.Input
              fluid
              label="Name"
              placeholder="Name"
              required
              onChange={handleInputChange}
              name="Name"
              value={infoUser.Name}
            />
          </FormSec.Group>
        </MdlSmt.Content>
        <MdlSmt.Actions>
          <Button
            color="green"
            type="submit"
            loading={loading}
            disabled={!infoUser.Email}
          >
            Crear
          </Button>
          <Button
            color="red"
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
            type="button"
          >
            Cancelar
          </Button>
        </MdlSmt.Actions>
      </MdlSmt>
    </div>
  );
}
