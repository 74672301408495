import React, { useEffect, useState, useRef } from "react";
import Loading from "../components/Loading";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Badge,
  FormGroup,
  ButtonGroup,
} from "reactstrap";
import { Card as CardSec, Button, Divider, Icon } from "semantic-ui-react";
import { invoiceSchema, initialInvoiceValues } from "../schemas/InvoiceSchema";
import { Formik, Form, Field } from "formik";
import {
  showSuccessToast,
  showErrorToast,
  showInfoToast,
} from "../components/Notify";
import NotFound from "../components/NotFound";
import { Editor as NewEditor } from "@tinymce/tinymce-react";
import FileInput from "../components/FileInput";
import QrInfo from "../containers/QrInfo";
import BreadcrumbTop from "../components/BreadcrumbTop";
import * as Yup from "yup";
import MicRecorder from "mic-recorder-to-mp3";
import useSound from "use-sound";

const useMount = (mount) => useEffect(mount, []);
const Mp3Recorder = new MicRecorder({
  bitRate: 128,
});
const timestamp = new Date().getTime();

export default function Invoice({ match, history, edit }) {
  const [initialValues, setInitialValues] = useState(initialInvoiceValues);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(true);
  const [newEditorContent, setNewEditorContent] = useState("");
  const [image, setImage] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [enablePasswordInput, setEnablePasswordInput] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDataAudio, setIsDataAudio] = useState(false);
  const initialState = {
    isRecording: false,
    blobURL: "",
    isBlocked: false,
    trackProgress: 0,
  };
  const [state, setState] = useState(initialState);

  const editorRef = useRef(null);
  let musicRef = useRef(null);
  let intervalRef = useRef(null);

  const id = match.params.id;
  const route = edit ? "/" : "/code-list";
  const sections = [
    { content: "Lista de códigos", destination: "/code-list" },
    { content: `Id: ${id}`, destination: "/null" },
  ];
  const enablePassworSchema = Yup.object().shape({
    pss: Yup.string().required().min(5),
  });

  useEffect(() => {
    navigator.getUserMedia(
      { audio: true },
      () => {
        setState({ isBlocked: false });
      },
      () => {
        showErrorToast({
          title: "Mensaje",
          message: "Permission Denied",
        });
        setState({ isBlocked: true });
      }
    );
  }, []);

  useEffect(() => {
    if (isPlaying) {
      musicRef?.current?.play?.();
      startTimer();
    } else {
      musicRef?.current?.pause?.();
      clearInterval(intervalRef.current);
    }
  }, [isPlaying]);

  useMount(() => {
    async function loadData() {
      try {
        await Axios.get(`${ROOT_URL}/qr/${id}`).then((response) => {
          if (response.status === 202) {
            setEnablePasswordInput(true);
            setLoading(false);
          } else {
            setInitialValues(response.data.Data);

            if (!(response.data.Data.Image === undefined)) {
              setImage(response.data.Data.Image);
            }

            if (!(response.data.Data.Text === undefined)) {
              setNewEditorContent(response.data.Data.Text);
            }

            if (
              !(
                response.data.Data.AudioURL === null ||
                response.data.Data.AudioURL === "" ||
                response.data.Data.AudioURL === undefined
              )
            ) {
              setIsDataAudio(true);
            }

            setLoading(false);
          }
        });
      } catch (error) {
        console.log(error);
        setNoData(false);
        setLoading(false);
      }
    }
    loadData();
  });

  async function onSubmit(values) {
    setLoading(true);

    if (state.blobURL) {
      try {
        const data = new FormData();
        data.append("voice", state.blobURL);

        Axios.put(`${ROOT_URL}/audio`, data)
          .then((response) => {
            showSuccessToast({
              title: "Mensaje",
              message: response.data.message,
            });
          })
          .catch(function (error) {
            showErrorToast({
              title: "Mensaje",
              message: error.response.data.message,
            });
            setLoading(false);
            return;
          });
      } catch (error) {
        showErrorToast({
          title: "Mensaje",
          message: error.response.data.message,
        });
        setLoading(false);
        return;
      }
    }

    values.Text = newEditorContent;

    if (values.URL !== "") {
      values.PhoneNumber = "";
      values.Map = "";
      values.YouTube = "";
      values.Text = "";
      setImage(null);
      setState(initialState);
    }

    // compruebe si los nuevos valores son diferentes de los valores iniciales y solo establezca como valores nuevos si lo son
    const dataEdit = Object.keys(values).reduce((acc, key) => {
      if (values[key] !== initialValues[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    if (dataEdit.URL === undefined || dataEdit.URL === null) {
      dataEdit.Text = newEditorContent;
      if (image) {
        dataEdit.Image = image;
      }
      if (state.blobURL) {
        dataEdit.AudioURL = `https://voice-recordes-qrs.s3.us-east-2.amazonaws.com/${id}.mp3`;
        dataEdit.enableAudio = true;
      }
    } else if (dataEdit.URL !== "") {
      dataEdit.Text = "";
      dataEdit.Image = "";
      dataEdit.AudioURL = "";
      dataEdit.enableAudio = false;
    }

    try {
      await Axios.put(`${ROOT_URL}/qr/${id}`, dataEdit).then((response) => {
        if (response.status === 200) {
          showSuccessToast({
            title: "Mensaje",
            message: response.data.message,
          });
        } else {
          showInfoToast({
            title: "Mensaje",
            message: response.data.message,
          });
        }
        setLoading(false);
        history.push(route);
      });
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
    }
  }

  const unlock = async (values) => {
    setLoading(true);

    try {
      const { data } = await Axios.get(
        `${ROOT_URL}/qr/${id}?action=view&password=${values.pss}`
      );
      setEnablePasswordInput(false);

      setInitialValues(data.Data);

      if (!(data.Data.Image === undefined)) {
        setImage(data.Data.Image);
      }

      if (!(data.Data.Text === undefined)) {
        setNewEditorContent(data.Data.Text);
      }

      if (
        !(
          data.Data.AudioURL === null ||
          data.Data.AudioURL === "" ||
          data.Data.AudioURL === undefined
        )
      ) {
        setIsDataAudio(true);
      }

      setLoading(false);
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!loading && !noData) {
    return <NotFound />;
  }

  const start = () => {
    if (state.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setState({ isRecording: true });
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = new File(buffer, `${id}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });
        setState({ blobURL, isRecording: false });
        const player = new Audio(URL.createObjectURL(blobURL));
        musicRef.current = player;
      })
      .catch((e) => console.log(e));
  };

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      if (musicRef?.current?.duration === musicRef?.current?.currentTime) {
        musicRef?.current?.pause?.();
        clearInterval(intervalRef?.current);
      }
      setState({
        ...state,
        trackProgress: musicRef?.current?.currentTime,
      });
    }, 100);
  };

  const onChangeTrackProgress = (e) => {
    setState({
      ...state,
      trackProgress: e.target.value,
    });
    musicRef.current.currentTime = e.target.value;
  };

  const MyButton = () => {
    const newAudioURL = `${initialValues.AudioURL}?timestamp=${timestamp}`;
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [, { sound }] = useSound(newAudioURL, {
      volume: 1,
      interrupt: true,
      loop: false,
      onload: () => {
        setAudioLoaded(true);
      },
    });

    const [isReproducing, setIsReproducing] = useState(false);

    useEffect(() => {
      if (sound) {
        sound.on("end", () => {
          setIsReproducing(false);
        });
      }
    }, [sound, audioLoaded]);

    const handlePlayPause = () => {
      if (isReproducing) {
        sound.pause();
        setIsReproducing(!isReproducing);
      } else {
        sound.play();
        setIsReproducing(!isReproducing);
      }
    };

    const deleteAudio = () => {
      const dataEditAudio = {
        AudioURL: "",
        enableAudio: false,
      };

      try {
        Axios.put(`${ROOT_URL}/qr/${id}`, dataEditAudio).then((response) => {
          if (response.status === 200) {
            showSuccessToast({
              title: "Mensaje",
              message: response.data.message,
            });
          } else {
            showInfoToast({
              title: "Mensaje",
              message: response.data.message,
            });
          }
          setLoading(false);
          window.location.reload();
        });
      } catch (error) {
        showErrorToast({
          title: "Mensaje",

          message: error.response.data.message,
        });
        setLoading(false);
      }
    };

    if (isDataAudio && audioLoaded) {
      return (
        <>
          <Button
            icon
            fluid
            labelPosition="left"
            type="button"
            onClick={() => handlePlayPause()}
            color="teal"
          >
            {isReproducing ? <Icon name="pause" /> : <Icon name="play" />}
            {isReproducing ? "Pause" : "Play"}
          </Button>
          <Button
            icon
            fluid
            labelPosition="left"
            type="button"
            onClick={() => {
              deleteAudio();
            }}
            color="red"
          >
            <Icon name="trash" />
            Delete Audio
          </Button>
        </>
      );
      // return (
      //   <>
      //     <Button icon fluid labelPosition="left" type="button" onClick={() => handlePlayPause()} color='teal'>
      //       {
      //         isReproducing ? <Icon name="pause" /> : <Icon name="play" />
      //       }
      //       {isReproducing ? "Pause" : "Play"}
      //     </Button>
      //     <Button icon fluid labelPosition="left" type="button" onClick={() => {
      //       deleteAudio();
      //       console.log("delete audio");
      //     }} color='red'>
      //       <Icon name="trash" />
      //       Delete Audio
      //     </Button>
      //   </>
      // );
    } else if (!audioLoaded) {
      return (
        <Button icon fluid labelPosition="left" type="button" color="gray">
          <Icon name="spinner" loading />
          Espera un momento, estamos cargando el audio
        </Button>
      );
    } else {
      return "No data audio";
    }
  };

  return (
    <>
      {!edit && <BreadcrumbTop sections={sections} />}
      {edit && <br />}
      {enablePasswordInput ? (
        <div className="padd-bot-top-10">
          <div className="Auth-form-container">
            <div className="Auth-form">
              <div className="Auth-form-content">
                <Formik
                  enableReinitialize={true}
                  initialValues={{ pss: null }}
                  validationSchema={enablePassworSchema}
                >
                  {({ errors, touched, values }) => {
                    return (
                      <Form>
                        <FormGroup row>
                          <Col md="12">
                            <FormGroup className="required">
                              <label>Ingrese la contraseña del QR</label>
                              <Field
                                type="password"
                                name="pss"
                                className="form-control"
                              />
                              {errors.pss && touched.pss ? (
                                <div>
                                  <Badge className="badge-color" color="danger">
                                    {errors.pss}
                                  </Badge>
                                </div>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col sm="12">
                            <FormGroup>
                              <Button
                                content="Enviar"
                                color="purple"
                                onClick={() => unlock(values)}
                                type="button"
                              />
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Container className="padd-1" fluid={true}>
          <Row>
            <Col sm="7">
              <Card className="trans">
                <CardBody>
                  <Row>
                    <CardSec.Group itemsPerRow={1}>
                      <CardSec color="purple">
                        <CardSec.Content extra>
                          <Formik
                            enableReinitialize={false}
                            initialValues={initialValues}
                            validationSchema={invoiceSchema}
                            onSubmit={onSubmit}
                          >
                            {({ errors, touched, values }) => {
                              if (
                                values.URL === undefined ||
                                values.URL === null
                              ) {
                                values.URL = "";
                              }
                              const urlFieldNotEmpty = values.URL !== "";

                              return (
                                <Form>
                                  <FormGroup row>
                                    <Col md="12" className="padd-bot-2">
                                      <FileInput
                                        fileProps={image}
                                        setFileProps={setImage}
                                        uploadingFile={uploadingFile}
                                        setUploadingFile={setUploadingFile}
                                        accept={"image/*"}
                                        qrId={id}
                                        // Se agrega esta propiedad para que el componente sepa cuando se puede
                                        // subir una imagen, solamente cuando el campo URL este vacio
                                        // Es decir que si el campo URL tiene un valor, no se podra subir una imagen
                                        disabled={urlFieldNotEmpty}
                                      />
                                    </Col>
                                    <Divider />
                                    <Col md="6" className="padd-bot-2">
                                      <FormGroup className="required">
                                        <label>Enlace URL</label>
                                        <Field
                                          type="url"
                                          name="URL"
                                          className="form-control"
                                        />
                                        {errors.URL && touched.URL ? (
                                          <div>
                                            <Badge className="badge-color">
                                              {errors.URL}
                                            </Badge>
                                          </div>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                    <Col md="6" className="padd-bot-2">
                                      <FormGroup>
                                        <label>Numero telefonico</label>
                                        <Field
                                          type="tel"
                                          name="PhoneNumber"
                                          className="form-control"
                                          disabled={urlFieldNotEmpty}
                                        />
                                        {errors.PhoneNumber &&
                                        touched.PhoneNumber ? (
                                          <div>
                                            <Badge className="badge-color">
                                              {errors.PhoneNumber}
                                            </Badge>
                                          </div>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                    <Col md="6" className="padd-bot-2">
                                      <FormGroup className="required">
                                        <label>Enlace de video Youtube</label>
                                        <Field
                                          type="url"
                                          name="YouTube"
                                          className="form-control"
                                          disabled={urlFieldNotEmpty}
                                        />
                                        {errors.YouTube && touched.YouTube ? (
                                          <div>
                                            <Badge className="badge-color">
                                              {errors.YouTube}
                                            </Badge>
                                          </div>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                    <Col md="6" className="padd-bot-2">
                                      <FormGroup className="required">
                                        <label>
                                          Proteger la información con contraseña
                                        </label>
                                        <Field
                                          type="password"
                                          name="Password"
                                          className="form-control"
                                        />
                                        {errors.Password && touched.Password ? (
                                          <div>
                                            <Badge className="badge-color">
                                              {errors.Password}
                                            </Badge>
                                          </div>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                    <Col md="12" className="padd-bot-2">
                                      <label>Audio</label>
                                      {isDataAudio ? (
                                        MyButton()
                                      ) : (
                                        <FormGroup>
                                          <ButtonGroup widths="3">
                                            <Button
                                              content="Record"
                                              icon="microphone"
                                              labelPosition="left"
                                              color="green"
                                              type="button"
                                              onClick={start}
                                              disabled={
                                                state.isRecording ||
                                                urlFieldNotEmpty
                                              }
                                            />
                                            <Button
                                              content="Stop"
                                              icon="stop"
                                              labelPosition="right"
                                              color="red"
                                              type="button"
                                              onClick={stop}
                                              disabled={
                                                !state.isRecording ||
                                                urlFieldNotEmpty
                                              }
                                            />
                                            <Button
                                              onClick={() =>
                                                setIsPlaying(!isPlaying)
                                              }
                                              content={
                                                isPlaying ? "Pause" : "Play"
                                              }
                                              icon={
                                                isPlaying ? "pause" : "play"
                                              }
                                              color="black"
                                              type="button"
                                              labelPosition="right"
                                              disabled={urlFieldNotEmpty}
                                            />
                                          </ButtonGroup>
                                          <div className="audio-player-progress">
                                            <input
                                              type="range"
                                              min={"0"}
                                              step={"0.01"}
                                              max={`${
                                                musicRef?.current?.duration || 0
                                              }`}
                                              value={`${
                                                state?.trackProgress || 0
                                              }`}
                                              class="slider"
                                              id="myRange"
                                              onChange={onChangeTrackProgress}
                                            />
                                          </div>
                                        </FormGroup>
                                      )}
                                    </Col>
                                    <Col sm="12" className="padd-bot-2">
                                      <FormGroup className="required">
                                        <label>Texto</label>
                                        <NewEditor
                                          apiKey="76hezx6td5lm2xvuaj1io6x8vc08rhf5hbstxnn442aaemr9"
                                          onInit={(evt, editor) =>
                                            (editorRef.current = editor)
                                          }
                                          initialValue={newEditorContent}
                                          init={{
                                            height: 250,
                                            menubar: false,
                                            toolbar:
                                              "undo redo | blocks | " +
                                              "bold italic forecolor | alignleft aligncenter " +
                                              "alignright alignjustify | bullist numlist outdent indent | " +
                                              "| help",
                                            content_style:
                                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                          }}
                                          disabled={urlFieldNotEmpty}
                                          onChange={(e) => {
                                            setNewEditorContent(
                                              e.target.getContent()
                                            );
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="12" className="padd-bot-2">
                                      <FormGroup>
                                        <Button
                                          content="Actualizar y salir"
                                          color="purple"
                                          type="submit"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </FormGroup>
                                </Form>
                              );
                            }}
                          </Formik>
                        </CardSec.Content>
                      </CardSec>
                    </CardSec.Group>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm="5">
              <Card className="trans">
                <CardBody>
                  <Row>
                    <CardSec.Group itemsPerRow={1}>
                      <CardSec color="purple">
                        <CardSec.Content extra>
                          <QrInfo
                            info={{ Data: initialValues }}
                            obj={"invoice"}
                          />
                        </CardSec.Content>
                      </CardSec>
                    </CardSec.Group>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
