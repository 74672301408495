import React from "react";
import { FormGroup, Badge, Label } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { showErrorToast } from "../components/Notify";
import { Image, Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export default function Login({ login }) {
  //formSchema se encarga de las validaciones y tipo de dato de los campos
  const formSchema = Yup.object().shape({
    Password: Yup.string().required("Escriba su contraseña"),
    Email: Yup.string().email().required("Proporcione un email"),
  });

  async function onSubmit(values) {
    try {
      await login(values.Email.toLowerCase(), values.Password);
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: error.response.data.message,
      });
    }
  }

  return (
    <div className="Auth-form-container">
      <Formik
        enableReinitialize={true}
        initialValues={{
          Password: "",
          Email: "",
        }}
        validationSchema={formSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form className="Auth-form">
            <div className="Auth-form-content">
              <div className="center">
                <Image
                  src="https://qrpdfs.s3.us-east-2.amazonaws.com/logo-stiqers-colorW2.png"
                  className="reduce-image"
                />
              </div>

              <br />
              <FormGroup>
                <Label className="input-title">*Correo</Label>
                <Field
                  type="email"
                  name="Email"
                  className="form-control"
                  required
                />
                {errors.Email && touched.Email ? (
                  <div>
                    <Badge className="badge-color" color="danger">
                      {errors.Email}
                    </Badge>
                  </div>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label className="input-title">*Contraseña</Label>
                <Field
                  type="password"
                  name="Password"
                  className="form-control"
                  required
                />
                {errors.Password && touched.Password ? (
                  <div>
                    <Badge className="badge-color" color="danger">
                      {errors.Password}
                    </Badge>
                  </div>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Button color="purple" type="submit" fluid>
                  Entrar
                </Button>
              </FormGroup>

              <FormGroup padd-bot-2>
                <NavLink to={`/`}>
                  <Button color="purple" fluid basic>
                    Página principal
                  </Button>
                </NavLink>
              </FormGroup>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
